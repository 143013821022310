import React from 'react'
import FeatherSVG from './FeatherSVG'

const pathFor = direction => {
  switch (direction) {
    case 'down':
      return <polyline points="6 9 12 15 18 9" />
    case 'left':
      return <polyline points="15 18 9 12 15 6" />
    case 'up':
      return <polyline points="18 15 12 9 6 15" />
    case 'right':
      return <polyline points="9 18 15 12 9 6" />
    default:
      throw new Error('invalid direction')
  }
}

const Chevron = ({ direction, ...props }) => (
  <FeatherSVG className="stroke-current-color" {...props}>
    <title>
      chevron-
      {direction}
    </title>
    {pathFor(direction)}
  </FeatherSVG>
)

export default Chevron
