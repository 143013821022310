import React from 'react'
import Helmet from 'react-helmet'
import Faq from 'components/Faq'
import Layout from 'components/Layout'
import Container from 'components/Container'
import faqs from 'data/faqs'

const Faqs = () => (
  <Layout>
    <Helmet title="Oregon City Smiles - FAQs" />
    <Container>
      <h1>FAQs</h1>
      <div className="mt-8 mx-auto mb-16">
        {faqs.map(({ q, a }, idx) => (
          <Faq key={idx} question={q} answer={a} />
        ))}
      </div>
    </Container>
  </Layout>
)

export default Faqs
