import React from 'react'
import { color } from 'styles'
import font from 'styles/font'
import { withProps } from 'recompose'

const iconSize = size => font.size[size] || size || '1.5rem'

const Svg = ({ size, ...props }) => (
  <svg
    style={{
      width: iconSize(size),
      height: iconSize(size),
      fill: color(props.fill) || 'none',
      stoke: props.stoke || 'currentColor',
      stokeLinecap: 'round',
      stokeLinejoin: 'round',
      pointerEvent: props.pointerEvent,
      verticalAlign: 'middle'
    }}
    {...props}
  />
)

export default withProps({
  xmlns: 'http://www.w3.org/2000/svg',
  viewBox: '0 0 24 24'
})(Svg)
