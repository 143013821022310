import React, { useState } from 'react'
import Chevron from 'components/icons/Chevron'

const StyledChevron = ({ active }) => (
  <div
    className="transition-transform"
    style={{
      transform: `rotate(${active ? 90 : 0}deg)`
    }}
  >
    <Chevron direction="right" />
  </div>
)

const Faq = ({ question, answer }) => {
  const [hide, setHide] = useState(true)

  return (
    <div className="p-2">
      <div
        className="flex items-center text-base"
        onClick={() => setHide(!hide)}
      >
        <StyledChevron active={!hide} />
        <span className="text-open-blue decoration-none ml-4">{question}</span>
      </div>
      <p className={`p-4 m-0 text-base ${hide && 'hidden'}`}>{answer}</p>
    </div>
  )
}

export default Faq
