export default [
  {
    q: ' Does bleaching damage the teeth?',
    a:
      'No. When carbamide peroxide, the active whitening agent, contacts water, hydrogen peroxide is released which whitens the teeth. Bleaching does not soften, demineralize or weaken the teeth.'
  },
  {
    q: 'Do over-the-counter bleaching products work?',
    a:
      'There is some evidence that over-the-counter bleaching products do whiten teeth, however, many of the products are too abrasive and can damage the teeth with extended use or misuse. Supervision by your dentist is always the safest and most effective way to whiten your teeth.'
  },
  {
    q: 'What are porcelain veneers and why are they used?',
    a:
      'Porcelain veneers are ultra-thin shells of ceramic material which are bonded to the front of the teeth. This procedure can be an ideal choice for improving the appearance of the front teeth by masking discolorations, whitening teeth and/or reshaping a smile.'
  },
  {
    q: 'What causes my jaw to pop when I open it?',
    a:
      'There is a pad or disk that separates the jaw bone from the base of the skull. The primary cause of the "popping" occurs when you open your mouth too wide and the jaw bone "pops" off the pad or disk. Treatment is not required unless pain is associated with the "pop" or the jaw locks.'
  },
  {
    q: 'What causes tooth decay?',
    a:
      'Tooth decay is caused by plaque in your mouth reacting with sugary and starchy deposits from food. This reaction produces acid which damages the enamel over time and weakens the tooth.'
  },
  {
    q: 'What can be done for ulcers or canker sores in the mouth?',
    a:
      'Ulcers are very difficult to treat. There is no proven technique that will eliminate ulcers or speed the recovery time once they appear. There are a few medications that will give temporary relief from the pain, but they need to be started as soon as symptoms appear. Ulcers will generally diminish and disappear in 7-10 days.'
  },
  {
    q: 'How can I stop grinding my teeth at night?',
    a:
      'Grinding your teeth can be very damaging to the teeth and also difficult to stop. If vigorous grinding occurs at night, teeth can be worn down to the gumline because the instinctive reflex to stop does not work while you are sleeping. Grinding due to stress can only be cured by removing the stress trigger. If grinding continues, a nightguard may be prescribed to prevent ultimate damage to the teeth.'
  },
  {
    q: 'Are there any alternatives to dentures?',
    a:
      'Dentures are no longer the only way to restore a mouth that has little or no non-restorable teeth. Strategically placed support, or implants, can now be used to support permanently cemented bridges, eliminating the need for a denture. The cost tends to be greater, but the implants and bridges more closely resemble the "feel" of real teeth. Dental implants are becoming the alternative of choice to dentures, but not everyone is a candidate for implants. Call your dentist for advice.'
  },
  {
    q:
      'Are silver fillings, fluoride or x-rays, a danger to my health? What are the alternatives?',
    a:
      "Dental amalgam, or silver filling material, is a mixture of mercury, and an alloy of silver, tin and copper. The release of mercury in silver fillings is so small that it is much less than what patients are exposed to in food, air and water. There are, however, other materials that can be used for restorations. These include gold, porcelain, and composite resins.  Fluoride is a compound of the element fluorine, which is found universally throughout nature in water, soil, air and in most foods. Fluoride is absorbed easily into the tooth enamel, especially in children's growing teeth. Once teeth are developed, fluoride makes the entire tooth structure more resistant to decay and promotes remineralization, which aids in repairing early decay before the damage is visible. Radiographs, or x-rays, help your dentist determine the presence or degree of periodontal disease, abscesses, and many abnormal growths such as cysts and tumors. They can help pinpoint the location of cavities and other signs of disease that may not be possible to detect through a visual examination. All health care providers are sensitive to patients' concerns about exposure to radiation. Your dentist has been trained to prescribe radiographs when they are appropriate and to tailor the radiograph schedule to your individual needs. By using state-of-the-art technology, such as digital radiography, and by staying knowledgeable about recent advances, your dentist knows which techniques, procedures and X-ray films can minimize your exposure to radiation."
  },
  {
    q: 'What are dental implants and how do they work?',
    a:
      'Dental implants are substitutes for natural tooth roots and rely on the jawbone for support. Strategically placed, implants can now be used to support permanently cemented bridges, eliminating the need for a denture. The cost tends to be greater, but the implants and bridges more closely resemble real teeth.'
  },
  {
    q: 'What is a root canal?',
    a:
      'A root canal is a procedure done to save the damaged or dead pulp in the root canal of the tooth by cleaning out the diseased pulp and reshaping the canal. The canal is filled with gutta percha, a rubberlike material, to prevent recontamination of the tooth. The tooth is then permanently sealed with possibly a post and/or a gold or porcelain crown. This enables patients to keep the original tooth.'
  },
  {
    q: 'When is the best time to remove wisdom teeth?',
    a:
      'When the removal of wisdom teeth is determined necessary, it is best done when the roots are approximately 2/3rds formed, usually in the adolescent years. Removal at this time allows for an easier procedure and decreases the risk of damage to the nerves in that area'
  },
  {
    q: 'What is the difference between a cap and a crown?',
    a: 'There is no difference between a cap and a crown.'
  },
  {
    q: 'At what age are my children supposed to see a dentist?',
    a:
      'The general rule is between 18 and 24 months. Some children require a bit more time to be comfortable. If an area of concern is noticed, then the child should see a dentist as soon as possible.'
  },
  {
    q:
      "Why is it important to fix baby teeth that have decay? Aren't they going to come out soon anyway?",
    a:
      'It is very important to maintain the baby teeth because these teeth hold space for the future eruption of the permanent teeth. If a baby tooth decays or is removed too early, the space necessary for the permanent teeth is lost and can only be regained through orthodontic treatment. Infected baby teeth can cause the permanent teeth to develop improperly resulting in stains, pits and weaker teeth.'
  },
  {
    q: 'When will my child lose his/her baby teeth?',
    a:
      'Children will begin losing their teeth at approximately age 5. They will usually lose their front teeth first. Children will continue to lose baby teeth until the age of 12 or 13 when all of the permanent teeth finally erupt.'
  },
  {
    q: 'When does thumb-sucking become damaging to the teeth?',
    a:
      'Generally, if the child has stopped sucking his/her thumb by age 5 there is no permanent damage. If the child is a vigorous and constant thumbsucker, however, there can be moderate to severe movement of teeth and prevention of normal bone growth.'
  },
  {
    q: 'Should my child wear a mouthguard while playing sports?',
    a:
      'It is strongly recommended that children wear a mouthguard while playing any contact sport. It is always better to prevent an injury than to repair one. The earlier a child begins to wear the mouthguard, the easier it is to become comfortable and continue to wear it as they get older.'
  },
  {
    q: 'should I do if my child gets a tooth knocked out?',
    a:
      "If the tooth is a permanent tooth, time is extremely crucial. Immediately stick the tooth back in the socket. Don't worry about getting it in straight or having it turned backwards, just get it in the socket and immediately call your dentist. If you are uncomfortable placing the tooth in the socket, put it in a glass of milk and get your child to the dentist as quickly as possible. If the tooth is a baby tooth, do not put it in the socket because damage to the permanent tooth can occur. When in doubt, put the tooth in milk and see your dentist immediately."
  },
  {
    q: 'What causes gum disease?',
    a:
      'Gum (periodontal) disease is caused by bacteria. These bacteria, if left along the gumline, will irritate the gums and cause an inflammation reaction. The gums then begin to bleed and swell allowing the bacteria to go deeper under the gumline. If the inflammation is allowed to continue, the bone will begin to demineralize and dissolve. As the bone dissolves around the teeth, the teeth become unsupported and will fall out. Unfortunately, pain does not occur until the final stages of the disease and treatment at that time has very little chance of being successful. If your gums bleed regularly, seek treatment as soon as possible.'
  },
  {
    q: 'Do water irrigation systems replace the need for flossing?',
    a:
      'Water irrigation systems should not be used as a substitute for brushing and flossing. These devices are effective in removing retained food from hard to reach areas, but do not remove plaque. Dentists frequently recommend these devices with the addition of antibacterial solutions to maintain the oral health of periodontal patients.'
  },
  {
    q: 'What is root planing and why is it done?',
    a:
      'Root planing is a technique performed in a dental office to stop the adverse affect of periodontal disease. The procedure cleans below the gumline and smooths the roots. When the roots are smoothed, the gums will usually reattach to the root stopping the bacteria from spreading. This stops and reverses some of the damage done by periodontal disease.'
  },
  {
    q: 'My gums bleed when I brush, what does it mean?',
    a:
      'Bleeding gums is an early indicator of gingivitis, or swollen gums,usually caused by plaque and/or calculus accumulated under the gumline. If left untreated, gingivitis can lead to bone loss and eventual tooth loss. Gingivitis can be reversed by proper brushing and flossing within a few weeks. If bleeding persists two to three weeks, consult your dentist.'
  },
  {
    q: 'How often should I see my dentist?',
    a:
      'You should visit your dentist at least every six months or more frequently to get your teeth cleaned. By seeing your dentist twice a year, your dentist can monitor your oral health and help you prevent any problems that may arise before they became uncomfortable or require more comprehensive or expensive treatment. The dentist may suggest more frequent visits, depending on the diagnosis.'
  },
  {
    q: 'What causes bad breath and what can be done about it?',
    a:
      'Bad breath, or halitosis, is primarily caused by poor oral hygiene, but can also can be caused by retained food particles, gum disease, drainage from sinus dripping or systemic, respiratory or gastrointestinal problems. Proper brushing including brushing the tongue, cheeks, and the roof of the mouth will remove bacteria and food particles. Flossing removes accumulated bacteria, plaque and food that may be trapped between teeth. Mouth rinses are effective in temporary relief of bad breath. Consult your dentist and/or physician if the condition persists.'
  }
]
